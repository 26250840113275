import React from "react";
import {Link} from "react-router-dom";
import silvercrossLogo from "../../assets/svg/silvercross.svg";

export default function Footer() {
  return (
    <div>
      <footer className="page_footer ds footer-color section_padding_top_100 section_padding_bottom_65 table_section">
        <div className="container">
          <div className="row ending">
            <div className="col-md-4 col-sm-6">
              <div className="widget widget_text">
                <a className="logo vertical_logo" href="/">
                  <img style={{textAlign: "center"}} className="carin_logo" src={silvercrossLogo} alt="Silvercross" />
                </a>
                <div className="media">
                  <div className="media-body">(A brand of Saveo Healthtech Pvt. Ltd.) </div>
                </div>
                <div className="media">
                  <div className="media-left rightpadding_10">
                    {" "}
                    <i className="fa fa-map-marker highlight" aria-hidden="true"></i>{" "}
                  </div>
                  <div className="media-body"> 41-3-79/2, Mysore Rd, near Manoj Cargo Carriers, New Timberyard Layout, Bengaluru, Karnataka 560026 </div>
                </div>
                <div className="media">
                  <div className="media-left rightpadding_10">
                    {" "}
                    <i className="fa fa-pencil highlight" aria-hidden="true"></i>{" "}
                  </div>
                  <div className="media-body highlight2links">
                    {" "}
                    <a className="media-body highlight2links" href="mailto:support@silvercross.in">
                      support@silvercross.in
                    </a>{" "}
                  </div>
                </div>
                <br />
                <p className="darklinks section_padding_bottom_6">
                  <a target="_blank" rel="noopener noreferrer" className="social-icon soc-facebook" href="https://www.facebook.com/p/SilverCross-Official-61550827695033/" title="Facebook">
                    Facebook
                  </a>
                  {/* <a target="_blank" rel="noopener noreferrer" className="social-icon soc-twitter" href="#" title="Twitter"></a> */}
                  {/* <a target="_blank" rel="noopener noreferrer" className="social-icon soc-google" href="#" title="Google"></a> */}
                  <a target="_blank" rel="noopener noreferrer" className="social-icon soc-instagram" href="https://www.instagram.com/silvercross.in/" title="Instagram">
                    Instagram
                  </a>
                  <a target="_blank" rel="noopener noreferrer" className="social-icon soc-linkedin" href="https://www.linkedin.com/company/silvercross-medi-sciences/posts/?feedView=all" title="Linkedin">
                    Linkedin
                  </a>
                  {/* <a target="_blank" rel="noopener noreferrer" className="social-icon soc-youtube" href="#" title="Youtube"></a> */}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6"></div>
            <div className="col-md-6 col-sm-6 footer-routes">
              <div className="media">
                <div className="media-body">
                  <a href="/">
                    <h5 className="logo-color1 widget-title">Home</h5>
                  </a>
                </div>
              </div>
              <div className="media">
                <div className="media-body">
                  <Link to="/about">
                    <h5 className="logo-color1 widget-title">About Us</h5>
                  </Link>{" "}
                </div>
              </div>
              <div className=" media">
                <div className="media-body">
                  <Link to="/offerings">
                    <h5 className="logo-color1 widget-title">Our Offerings</h5>
                  </Link>{" "}
                </div>
              </div>
              <div className=" media">
                <div className="media-body">
                  <Link to="/community">
                    <h5 className="logo-color1 widget-title">Our Reach</h5>
                  </Link>{" "}
                </div>
              </div>
              {/* <div className=" media">
                <div className="media-body">
                  <Link to="/blogs">
                    <h5 className="logo-color1 widget-title">Blogs</h5>
                  </Link>
                </div>
              </div> */}
              <div className=" media">
                <div className="media-body">
                  <Link to="/careers">
                    <h5 className="logo-color1 widget-title">Careers</h5>
                  </Link>{" "}
                </div>
              </div>
              <div className=" media">
                <div className="media-body">
                  <Link to="/contact">
                    <h5 className="logo-color1 widget-title">Contact</h5>
                  </Link>{" "}
                </div>
              </div>
              <div className=" media">
                <div className="media-body">
                  <a href={process.env.REACT_APP_SC_BASE_URL + "/silvercross_privacy_policy.pdf"} target="_blank" rel="noopener noreferrer">
                    <h5 className="logo-color1 widget-title">Privacy Policy</h5>
                  </a>{" "}
                </div>
              </div>
              {/* <div className=" media">
                <div className="media-body">
                  <a href="https://www.carin.life/sitemap" target="_blank" rel="noopener noreferrer">
                    <h5 className="logo-color1 widget-title">Sitemap</h5>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      <section className="ds logo-color  page_copyright">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p className="fontsize_12 color-white">&copy; Copyright 2025. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
