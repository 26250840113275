import React, {useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import {Paper} from "@material-ui/core";
import axios from "axios";
import {Helmet} from "react-helmet";
// import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {productCategories} from "../App";
import GppGoodIcon from "@mui/icons-material/GppGood";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 4,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};
export default function Home() {
  //const mediaMatch = window.matchMedia('(min-width: 1300px)');
  //const [matches, setMatches] = useState(mediaMatch.matches);
  const [blogs, setBlogs] = useState([]);
  const {categories} = useContext(productCategories);
  async function fetchBlogs() {
    try {
      setBlogs(blogs);
      const response = await axios.get(`${process.env.REACT_APP_API}/blogs`);
      // console.log(response.data);
      setBlogs(response.data);
    } catch (e) {
      // console.log(e);
      setBlogs(blogs);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogs();

    // const handler = e => setMatches(e.matches);
    // mediaMatch.addListener(handler);
    // return () => mediaMatch.removeListener(handler);
  }, []);

  return (
    //<div style={matches ? {background: `url('${'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images'}/carin_pattern2.svg'), url('${'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images'}/carin_pattern2.svg')`, backgroundRepeat: "repeat-y", backgroundPosition: "left, right", backgroundSize: "7.5%, 7.5%"} : {}}>
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose"
        />
        <link rel="canonical" href="https://www.silvercross.in" />
      </Helmet>
      <section className="intro_section page_mainslider">
        <div className="starting text-center">
          <Carousel showArrows={true} stopOnHover={false} autoPlay autoFocus dynamicHeight infiniteLoop interval={"2500"}>
            <Paper>
              <img src={process.env.REACT_APP_ASSETS_URL + "/homeBanner1.jpg"} alt="First slide" />
            </Paper>
            <Paper>
              <img src={process.env.REACT_APP_ASSETS_URL + "/homeBanner2.jpg"} alt="Second slide" />
            </Paper>
            <Paper>
              <img src={process.env.REACT_APP_ASSETS_URL + "/homeBanner3.jpg"} alt="Third slide" />
            </Paper>
          </Carousel>
        </div>
      </section>
      {/* <section className="intro_section page_mainslider">
                <div className="flexslider starting text-center landing" data-nav="false">
                    <ul className="slides">
                        <li> <img className="landing-images" src="https://d214gcurai71bm.cloudfront.net/carin-images/LandingPageI_.jpg" alt="" />
                        </li>
                        <li> <img className="landing-images" src="https://d214gcurai71bm.cloudfront.net/carin-images/LandingPageII_.jpg" alt="" />
                        </li>
                        <li> <img className="landing-images" src="https://d214gcurai71bm.cloudfront.net/carin-images/LandingPageIII_.jpg" alt="" />
                        </li>
                    </ul>
                </div>
            </section> */}
      <section id="products" className="offerings color parallax section_padding_top_130 section_padding_bottom_150 columns_padding_25">
        <div className="container">
          <h2 style={{textAlign: "center"}} className="section_header">
            Silvercross Products See Our Offerings
          </h2>
          <div className="row display_table_md">
            <div className="col-md-6 col-md-push-6 display_table_cell_md">
              <p className="offering" style={{fontSize: "20px", fontWeight: 400}}>
                Browse our extensive range of healthcare products crafted with precision and care. Every SilverCross product undergoes stringent quality checks and can be verified by scanning a QR code on the packaging. Explore categories like Pain Relief, Gastrointestinal Health, Antifungal Medications, and more.
              </p>
            </div>
            <div className="col-md-6 col-md-pull-6 display_table_cell_md">
              <img src={process.env.REACT_APP_ASSETS_URL + "/categoriesCover.jpeg"} alt="" className="rounded" />
            </div>
          </div>
          <br />
          <div className="row topmargin_30">
            <div className="col-sm-12" style={{overflow: "hidden"}}>
              <MultiCarousel className="homeOfferingsSlider" itemClass="carousel-item-padding-40-px" responsive={responsive} keyBoardControl={true}>
                {categories.map((data) => {
                  return (
                    <div className="gallery-item rounded">
                      <div className="item-media rounded overflow_hidden" style={{height: "80%", width: "80%", overflow: "hidden", margin: "0px auto"}}>
                        <img src={`${process.env.REACT_APP_ASSETS_URL}/${data.category}.jpeg`} alt={data.category} />
                        <div className="media-links">
                          {" "}
                          <Link to={`/offerings/${data.category}?id=${data.id}`} className="abs-link prettyPhoto"></Link>{" "}
                        </div>
                        <div className="offering-titles" style={{marginTop: "8px"}}>
                          {" "}
                          <strong>{data.category}</strong>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </MultiCarousel>
            </div>
          </div>

          {/* <div className="row topmargin_30">
            <div className="col-sm-12">
              <div
                className="owl-carousel gallery-carousel"
                data-responsive-lg="5"
                data-responsive-md="4"
                data-responsive-sm="3"
                data-responsive-xs="1"
                data-nav="true"
              >
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/gynaecology.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/gynaecology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Gynaecology </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/cardiac.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/cardiovascular_metabolics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">
                      Cardiovascular Metabolics{" "}
                    </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/diabetes.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/anti-diabetics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Anti-Diabetics </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/respiratory.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/respiratory"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Respiratory </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/derma.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/dermatology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Dermatology </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/analgesics.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/analgesics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Pain/Analgesics </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={
                        'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/anti_infectives.jpg"
                      }
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/anti-infectives"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Anti-Infectives </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/gastro.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/gastrology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Gastrology</p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={'https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images' + "/vitamins.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/vitamins"
                        className="abs-link prettyPhoto"
                        title=""
                        data-gal="prettyPhoto[gal]"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Vitamins</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section style={{padding: "20px 0"}} id="features" className="columns_margin_bottom_30">
        <div className="container">
          <div className="row why">
            <div className="col-md-4 col-sm-12 whyus margin_auto" style={{textAlign: "center"}}>
              <h3 className="section_header">Why choose SilverCross?</h3>{" "}
              <Link to="/about" className="theme_button color2 min_width_button">
                About Us
              </Link>{" "}
            </div>
            <div className="col-md-4 col-sm-12 whyus">
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="rounded main_bg_color homeIconDiv">
                    {" "}
                    <GppGoodIcon />
                  </div>
                </div>

                <div className="media-body">
                  <h5 style={{fontWeight: 500}} className="hover-color2">
                    Transparency{" "}
                  </h5>
                  <p>
                    {" "}
                    Verify every batch with a QR code <span style={{visibility: "hidden"}}>Verify every batch with a QR code</span>{" "}
                  </p>
                </div>
              </div>
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-cloud color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{fontWeight: 500}} className="hover-color2">
                    Accessibility{" "}
                  </h5>
                  <p> Available across 10,000+ pharmacies in India</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 whyus ">
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-diamond2 color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{fontWeight: 500}} className="hover-color2">
                    Excellent Quality
                  </h5>
                  <p>Our products meet 100% dissolution standards </p>
                </div>
              </div>
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-tag2 color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{fontWeight: 500}} className="hover-color2">
                    Reward
                  </h5>
                  <p> All our retailers all partners in branded gifts, incentives, tour packages. We grow together.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div>
        <div className=" to_animate  container about_home" style={{padding: "20px"}} data-animation="fadeInLeft" data-delay="150">
          <h3 className="logo-color1">About Carin - Digital-first healthcare brand</h3>
          <ul>
            {" "}
            <li>
              <p>Carin is an assistive, Digital-First Healthcare brand. We at Carin believe that technology can be a great lever which can make Quality Healthcare much more accessible.</p>
            </li>
            <li>
              <p>With Carin healthcare products, you can be assured of the quality.</p>
            </li>
            <li>
              <p>Carin strives for a better future, for all.</p>
            </li>
          </ul>
        </div>
      </div> */}
      {/* <section id="features" className="ls section_padding_top_150 section_padding_bottom_130 columns_margin_bottom_30">
        <div className="container widget widget_recent_posts ">
          <h2 className="section_header text-center">Recent Carin health Blogs</h2>
          {blogs.length && (
            <div>
              <BlogsIntro data={blogs[0]} />
              <BlogsIntro data={blogs[1]} />
              <BlogsIntro data={blogs[2]} />
            </div>
          )}
        </div>
      </section> */}
      {/* <br /> */}
    </div>
  );
}
