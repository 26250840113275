import React, {useEffect, useRef, useState} from "react";
import HexagonalDesign from "./HexagonalDesign.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {PDFViewer} from "./PDFViewer";
import Lottie from "react-lottie-player";
import VerifiedAnim from "../../assets/lotties/verified.json";
import FailedAnim from "../../assets/lotties/failed.json";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import Loader from "../../assets/svg/loader.svg";
import CloseIcon from "@mui/icons-material/Close";
const getQrProduct = async (adminLang, name) => {
  const result = await axios.get(`${process.env.REACT_APP_SILVERCROSS_API}/products/qr/${adminLang}/${name}`);
  return result;
};
const ProductQrPage = () => {
  const [popUpAction, setPopUpAction] = useState(false);
  const router = useHistory();
  const location = useLocation();
  const [product, setProduct] = useState({});
  const [selectedBatchFile, setBatchFile] = useState(null);
  const [batchFiles, setBatchFiles] = useState(null);
  const [type, setType] = useState("");
  const [content, setContent] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [uniqueId, setUniqueId] = useState([]);
  const [batchNoVerify, setBatchNoVerify] = useState("");
  const [showBatchFile, setShowBatchFile] = useState(false);
  const [verifiedBatch, setVerifiedBatch] = useState("");
  const inpRef = useRef();
  const inpRef2 = useRef();
  const inpRef3 = useRef();
  const inpRef4 = useRef();
  const [backCount, setBackCount] = useState(0);

  const handlePopUp = (event, modeType) => {
    event.stopPropagation();

    if (!popUpAction) {
      setType(modeType);

      if (modeType === "Mode of Action") {
        setContent(product.mode_action);
      } else if (modeType === "Side Effects") {
        setContent(product.side_effects);
      }
      if (modeType === "How to use?") {
        setContent(product.dosage);
      }
      if (modeType === "FAQs") {
        setContent(product.FAQ_ques);
      }
      if (modeType === "Indications") {
        setContent(product.indications);
      }
      if (modeType === "Drug Interaction") {
        setContent(product.interaction);
      }
    } else {
      setType("");
      setContent("");
    }
    setPopUpAction(!popUpAction);
  };

  const fetchProduct = async () => {
    try {
      const {pathname} = location;
      const response = await getQrProduct("eng", pathname.split("/").pop());

      let tempList = response.data.productList[0];
      if (tempList.batchList) {
        setBatchFiles(tempList.batchList);
      }

      setProduct(tempList);
      tempList.FAQ_ques && setQuestions(tempList.FAQ_ques.split("&"));
      tempList.FAQ_ans && setAnswers(tempList.FAQ_ans.split("&"));
      setLoading(false);

      if (tempList.batchList !== null) {
        openBatchModal(tempList.batchList);
      }
    } catch (error) {
      console.log({error});
      setLoading(false);
      router.push("/");
    }
  };

  const openBatchModal = (batch) => {
    setShowBatchModal(true);

    if (batch && batchNoVerify !== "verified") {
      setTimeout(() => {
        inpRef.current && inpRef.current.focus();
      }, 200);
    }
  };

  const checkUniqueId = (e, uid, id) => {
    let tempUID = [...uniqueId];
    let uidIndx = tempUID.findIndex((ele) => ele.uid === uid);

    if (e.keyCode !== 9 && e.keyCode !== 8) {
      if (id) {
        if (uidIndx >= 0) {
          tempUID[uidIndx].id = id.toUpperCase();
        } else {
          tempUID.push({uid: uid, id: id.toUpperCase()});
        }

        if (uid === "uid1") {
          inpRef.current && inpRef2.current.focus();
        } else if (uid === "uid2") {
          inpRef.current && inpRef3.current.focus();
        } else if (uid === "uid3") {
          inpRef.current && inpRef4.current.focus();
        }

        setUniqueId(tempUID);
      }
    } else if (e.keyCode === 8) {
      if (backCount + 1 === 2) {
        if (uid === "uid2") {
          inpRef.current && inpRef.current.focus();
        } else if (uid === "uid3") {
          inpRef.current && inpRef2.current.focus();
        } else if (uid === "uid4") {
          inpRef.current && inpRef3.current.focus();
        }

        if (uidIndx !== -1 && !id) {
          tempUID.splice(uidIndx, 1);
          setUniqueId(tempUID);
        }
        setBackCount(0);
      } else {
        setBackCount((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (uniqueId.length === 4) {
      setLoading(true);

      for (let i = 0; i < batchFiles.length; i++) {
        let checkBatchNo = batchFiles[i].batchId.toUpperCase().split("");

        if (uniqueId[0].id === checkBatchNo[0] && uniqueId[1].id === checkBatchNo[1] && uniqueId[2].id === checkBatchNo[checkBatchNo.length - 2] && uniqueId[3].id === checkBatchNo[checkBatchNo.length - 1]) {
          setLoading(false);
          setVerifiedBatch(checkBatchNo);
          setBatchFile(batchFiles[i]);
          setBatchNoVerify("verified");
          break;
        } else if (i === batchFiles.length - 1) {
          setLoading(false);
          setBatchNoVerify("failed");
        }
      }
    }
  }, [uniqueId]);

  useEffect(() => {
    setTimeout(() => {
      if (batchNoVerify === "verified") {
        setUniqueId([]);
        setShowBatchFile(true);
      } else if (batchNoVerify === "failed") {
        setUniqueId([]);
        setBatchNoVerify("");
      }
    }, [5000]);
  }, [batchNoVerify]);

  useEffect(() => {
    setLoading(true);
    const lang = localStorage.getItem("scLang");

    if (!lang) {
      localStorage.setItem("scLang", "eng");
    }

    window.scrollTo(0, 0);
    fetchProduct();
  }, [router]);

  useEffect(() => {
    if (showBatchModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showBatchModal]);
  return (
    <section className="qr-page-cont">
      {loading ? (
        <div className="loader-cntr">
          <img src={Loader} alt="Loader" />
        </div>
      ) : (
        <>
          {Object.keys(product).length > 0 && (
            <>
              <HexagonalDesign handlePopUp={handlePopUp} product={product} openBatchModal={openBatchModal} selectedBatchFile={selectedBatchFile} />

              {popUpAction && (
                <div className="hex-panel-cont">
                  <div className="hex-panel">
                    <CloseIcon className="close-hex-panel icofont-close-squared-alt" style={{zIndex: "10", marginTop: "-5px"}} onClick={handlePopUp} />

                    <div>
                      <strong style={{fontSize: "1.5rem", color: "#522e80"}}>{type}</strong>

                      <hr />

                      {type !== "FAQs" ? (
                        <p>{content}</p>
                      ) : (
                        <>
                          {questions &&
                            questions.map((que, idx) => {
                              return (
                                <div key={idx + "_accordProd"} style={{marginTop: "1rem"}}>
                                  <Accordion className="accordion">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                      <Typography>
                                        {idx + 1}. {que}
                                      </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                      <Typography
                                        style={{
                                          fontWeight: "300",
                                          fontSize: "0.9rem",
                                        }}>
                                        {answers[idx]}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Batch Certificate Modal */}
              <div className={showBatchModal ? "batch-popup" : "close-batch"}>
                <div className="batch-cntr" style={{height: product.batchList === null || (showBatchFile && selectedBatchFile && !selectedBatchFile.batchFile) ? "150px" : showBatchFile || (batchNoVerify && showBatchFile) ? "" : "350px"}}>
                  <div className="batch-header-popup">
                    <p>BATCH CERTIFICATE</p>
                    {((!batchNoVerify && !showBatchFile) || (batchNoVerify === "verified" && showBatchFile)) && <CloseIcon style={{cursor: "pointer"}} onClick={() => setShowBatchModal(false)} />}
                  </div>

                  {product.batchList === null ? (
                    <h5>Batch Certificate is not available</h5>
                  ) : (
                    <>
                      {!batchNoVerify && !showBatchFile && (
                        <div className="div-cntr" style={{height: "100%"}}>
                          <p className="uid-info">
                            Thank you for scanning Med-scan of <b>{product.brand_name}</b>.
                          </p>
                          <p className="uid-info">ⓘ To verify the batch number, please enter the first two and the last two digits of the batch number.</p>

                          <div className="uniqueId-cntr">
                            <input autoFocus ref={inpRef} type="text" name="uid1" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                            <input type="text" ref={inpRef2} name="uid2" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                            {Array.from(new Array(product.batchList[0].batchId.length - 4), (el, indx) => (
                              <p>X</p>
                            ))}
                            <input type="text" ref={inpRef3} name="uid3" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                            <input type="text" ref={inpRef4} name="uid4" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                          </div>

                          <p className="uid-sample">
                            <span>eg:</span>
                            <b>B</b>
                            <b>S</b>X-835<b>2</b>
                            <b>1</b>
                          </p>

                          <p className="uid-power">
                            <i>Powered by Silvercross</i>
                          </p>
                        </div>
                      )}

                      {batchNoVerify && !showBatchFile && (
                        <div className="div-cntr" style={{height: "100%"}}>
                          {batchNoVerify === "verified" && (
                            <>
                              <Lottie animationData={VerifiedAnim} play style={{height: "150px"}} />
                              <p className="ver-pass">
                                Batch Certificate No. <span>{verifiedBatch}</span> is verified
                              </p>
                            </>
                          )}

                          {batchNoVerify === "failed" && (
                            <>
                              <Lottie animationData={FailedAnim} play style={{height: "150px"}} />
                              <p className="ver-fail">Invalid batch number, please enter a valid batch number.</p>
                              <p className="uid-info">
                                <i>To report any suspicious batch, please contact 83900 8888 or email at support@silvercross.in</i>
                              </p>
                            </>
                          )}
                        </div>
                      )}

                      {showBatchFile && !selectedBatchFile.batchFile && <h5>Batch Certificate is coming soon</h5>}

                      {showBatchFile && selectedBatchFile.batchFile && (
                        <>
                          <div className="batch-pdf-cntr">
                            <PDFViewer batchUrl={selectedBatchFile.batchFile} />
                          </div>

                          <div className="batch-cta-cntr">
                            <button onClick={() => setShowBatchModal(false)}>Show {product.brand_name} Details</button>
                            <button onClick={() => window.open(`https://d3ovr3jl9wbjnk.cloudfront.net/batch_certificates/${selectedBatchFile.batchFile}`, "_blank")}>Download Batch&nbsp;Certificate</button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default ProductQrPage;
