import React, {useEffect, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import "react-pdf/dist/esm/Page/TextLayer.css";
import {FiChevronLeft, FiChevronRight, FiDownload} from "react-icons/fi";
import {useLocation} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Downloads = () => {
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [path, setPath] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [docFile, setDocFile] = useState(null);

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(+1);
  };

  const pdfUrl = async (url) => {
    let blob = await fetch(`https://s3.ap-south-1.amazonaws.com/data.silvercross.in/downloads/${url}`).then((r) => r.blob());
    setDocFile(blob);
  };

  // useEffect(() => {
  //   if (router.query.path) {
  //     setPath(router.query.path);
  //     setFileName(router.query.fileName);
  //     pdfUrl(router.query.path + "/" + router.query.fileName);
  //     setLoading(false);
  //   }
  // }, [router]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Parse query parameters
    const queryPath = queryParams.get("path");
    const queryFileName = queryParams.get("fileName");
    console.log({queryParams, queryPath});
    if (queryPath) {
      setPath(queryPath);
      setFileName(queryFileName);
      pdfUrl(`${queryPath}/${queryFileName}`); // Your function to handle PDF URL
      setLoading(false);
    }
  }, [location]);
  return (
    <div>
      {loading ? (
        <div className="loader-cntr">
          <img src="/images/loader.svg" alt="Loader" />
        </div>
      ) : (
        <>
          {/* <Navbar /> */}

          <div className="download-cntr">
            <div className="download-panel">
              <div className="download-top-cntr">
                <Document
                  file={docFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <div className="loader-cntr">
                      <img src="/images/loader.svg" alt="Loader" />
                    </div>
                  }
                  error={"Checking PDF..."}
                  noData={"Loading PDF..."}>
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>

              {!loading && docFile && (
                <div className="download-bottom-cntr">
                  <div className="download-pg">
                    <p>
                      Page {pageNumber || (numPages ? 1 : "--")} - {numPages || "--"}
                    </p>

                    <button style={{background: "white"}} type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                      <FiChevronLeft />
                    </button>

                    <button style={{background: "white"}} type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
                      <FiChevronRight />
                    </button>
                  </div>

                  <a className="download-btn" href={`https://s3.ap-south-1.amazonaws.com/data.silvercross.in/downloads/${path}/${fileName}`} target="_blank">
                    <button>
                      <FiDownload />
                    </button>
                    <p>DOWNLOAD</p>
                  </a>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Downloads;
